/**
 * Cryptocurrency Icons, 0.13.0
 * Created by atomiclabs
 * http://cryptoicons.co
 * CC0-1.0 License
 */
@charset "UTF-8";

@font-face {
  font-family: "cryptocurrency-icons";
  src: url('./cryptocurrency-icons.eot');
  src: 
    url('./cryptocurrency-icons.eot?#iefix') format('embedded-opentype'),
    url('./cryptocurrency-icons.woff2') format('woff2'),
    url('./cryptocurrency-icons.woff') format('woff'),
    url('./cryptocurrency-icons.ttf') format('truetype'),
    url('./cryptocurrency-icons.svg#cryptocurrency-icons') format('svg');
}

.crypto {
  font-family: "cryptocurrency-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-decoration: none;
  text-transform: none;
}


.crypto-2give::before {
  content: "\ea01";
}

.crypto-abt::before {
  content: "\ea02";
}

.crypto-act::before {
  content: "\ea03";
}

.crypto-actn::before {
  content: "\ea04";
}

.crypto-ada::before {
  content: "\ea05";
}

.crypto-add::before {
  content: "\ea06";
}

.crypto-adx::before {
  content: "\ea07";
}

.crypto-ae::before {
  content: "\ea08";
}

.crypto-aeon::before {
  content: "\ea09";
}

.crypto-aeur::before {
  content: "\ea0a";
}

.crypto-agi::before {
  content: "\ea0b";
}

.crypto-agrs::before {
  content: "\ea0c";
}

.crypto-aion::before {
  content: "\ea0d";
}

.crypto-amb::before {
  content: "\ea0e";
}

.crypto-amp::before {
  content: "\ea0f";
}

.crypto-ant::before {
  content: "\ea10";
}

.crypto-apex::before {
  content: "\ea11";
}

.crypto-appc::before {
  content: "\ea12";
}

.crypto-ardr::before {
  content: "\ea13";
}

.crypto-arg::before {
  content: "\ea14";
}

.crypto-ark::before {
  content: "\ea15";
}

.crypto-arn::before {
  content: "\ea16";
}

.crypto-ary::before {
  content: "\ea17";
}

.crypto-ast::before {
  content: "\ea18";
}

.crypto-atm::before {
  content: "\ea19";
}

.crypto-audr::before {
  content: "\ea1a";
}

.crypto-auto::before {
  content: "\ea1b";
}

.crypto-aywa::before {
  content: "\ea1c";
}

.crypto-bab::before {
  content: "\ea1d";
}

.crypto-bat::before {
  content: "\ea1e";
}

.crypto-bay::before {
  content: "\ea1f";
}

.crypto-bcbc::before {
  content: "\ea20";
}

.crypto-bcc::before {
  content: "\ea21";
}

.crypto-bcd::before {
  content: "\ea22";
}

.crypto-bch::before {
  content: "\ea23";
}

.crypto-bcio::before {
  content: "\ea24";
}

.crypto-bcn::before {
  content: "\ea25";
}

.crypto-bco::before {
  content: "\ea26";
}

.crypto-bcpt::before {
  content: "\ea27";
}

.crypto-bdl::before {
  content: "\ea28";
}

.crypto-beam::before {
  content: "\ea29";
}

.crypto-bela::before {
  content: "\ea2a";
}

.crypto-bix::before {
  content: "\ea2b";
}

.crypto-blcn::before {
  content: "\ea2c";
}

.crypto-blk::before {
  content: "\ea2d";
}

.crypto-block::before {
  content: "\ea2e";
}

.crypto-blz::before {
  content: "\ea2f";
}

.crypto-bnb::before {
  content: "\ea30";
}

.crypto-bnt::before {
  content: "\ea31";
}

.crypto-bnty::before {
  content: "\ea32";
}

.crypto-booty::before {
  content: "\ea33";
}

.crypto-bos::before {
  content: "\ea34";
}

.crypto-bpt::before {
  content: "\ea35";
}

.crypto-bq::before {
  content: "\ea36";
}

.crypto-brd::before {
  content: "\ea37";
}

.crypto-bsd::before {
  content: "\ea38";
}

.crypto-bsv::before {
  content: "\ea39";
}

.crypto-btc::before {
  content: "\ea3a";
}

.crypto-btcd::before {
  content: "\ea3b";
}

.crypto-btch::before {
  content: "\ea3c";
}

.crypto-btcp::before {
  content: "\ea3d";
}

.crypto-btcz::before {
  content: "\ea3e";
}

.crypto-btdx::before {
  content: "\ea3f";
}

.crypto-btg::before {
  content: "\ea40";
}

.crypto-btm::before {
  content: "\ea41";
}

.crypto-bts::before {
  content: "\ea42";
}

.crypto-btt::before {
  content: "\ea43";
}

.crypto-btx::before {
  content: "\ea44";
}

.crypto-burst::before {
  content: "\ea45";
}

.crypto-call::before {
  content: "\ea46";
}

.crypto-cc::before {
  content: "\ea47";
}

.crypto-cdn::before {
  content: "\ea48";
}

.crypto-cdt::before {
  content: "\ea49";
}

.crypto-cenz::before {
  content: "\ea4a";
}

.crypto-chain::before {
  content: "\ea4b";
}

.crypto-chat::before {
  content: "\ea4c";
}

.crypto-chips::before {
  content: "\ea4d";
}

.crypto-cix::before {
  content: "\ea4e";
}

.crypto-clam::before {
  content: "\ea4f";
}

.crypto-cloak::before {
  content: "\ea50";
}

.crypto-cmm::before {
  content: "\ea51";
}

.crypto-cmt::before {
  content: "\ea52";
}

.crypto-cnd::before {
  content: "\ea53";
}

.crypto-cnx::before {
  content: "\ea54";
}

.crypto-cny::before {
  content: "\ea55";
}

.crypto-cob::before {
  content: "\ea56";
}

.crypto-colx::before {
  content: "\ea57";
}

.crypto-coqui::before {
  content: "\ea58";
}

.crypto-cred::before {
  content: "\ea59";
}

.crypto-crpt::before {
  content: "\ea5a";
}

.crypto-crw::before {
  content: "\ea5b";
}

.crypto-cs::before {
  content: "\ea5c";
}

.crypto-ctr::before {
  content: "\ea5d";
}

.crypto-ctxc::before {
  content: "\ea5e";
}

.crypto-cvc::before {
  content: "\ea5f";
}

.crypto-d::before {
  content: "\ea60";
}

.crypto-dai::before {
  content: "\ea61";
}

.crypto-dash::before {
  content: "\ea62";
}

.crypto-dat::before {
  content: "\ea63";
}

.crypto-data::before {
  content: "\ea64";
}

.crypto-dbc::before {
  content: "\ea65";
}

.crypto-dcn::before {
  content: "\ea66";
}

.crypto-dcr::before {
  content: "\ea67";
}

.crypto-deez::before {
  content: "\ea68";
}

.crypto-dent::before {
  content: "\ea69";
}

.crypto-dew::before {
  content: "\ea6a";
}

.crypto-dgb::before {
  content: "\ea6b";
}

.crypto-dgd::before {
  content: "\ea6c";
}

.crypto-dlt::before {
  content: "\ea6d";
}

.crypto-dnt::before {
  content: "\ea6e";
}

.crypto-dock::before {
  content: "\ea6f";
}

.crypto-doge::before {
  content: "\ea70";
}

.crypto-drgn::before {
  content: "\ea71";
}

.crypto-drop::before {
  content: "\ea72";
}

.crypto-dta::before {
  content: "\ea73";
}

.crypto-dth::before {
  content: "\ea74";
}

.crypto-dtr::before {
  content: "\ea75";
}

.crypto-ebst::before {
  content: "\ea76";
}

.crypto-eca::before {
  content: "\ea77";
}

.crypto-edg::before {
  content: "\ea78";
}

.crypto-edo::before {
  content: "\ea79";
}

.crypto-edoge::before {
  content: "\ea7a";
}

.crypto-ela::before {
  content: "\ea7b";
}

.crypto-elf::before {
  content: "\ea7c";
}

.crypto-elix::before {
  content: "\ea7d";
}

.crypto-ella::before {
  content: "\ea7e";
}

.crypto-emc::before {
  content: "\ea7f";
}

.crypto-emc2::before {
  content: "\ea80";
}

.crypto-eng::before {
  content: "\ea81";
}

.crypto-enj::before {
  content: "\ea82";
}

.crypto-entrp::before {
  content: "\ea83";
}

.crypto-eon::before {
  content: "\ea84";
}

.crypto-eop::before {
  content: "\ea85";
}

.crypto-eos::before {
  content: "\ea86";
}

.crypto-eqli::before {
  content: "\ea87";
}

.crypto-equa::before {
  content: "\ea88";
}

.crypto-etc::before {
  content: "\ea89";
}

.crypto-eth::before {
  content: "\ea8a";
}

.crypto-ethos::before {
  content: "\ea8b";
}

.crypto-etn::before {
  content: "\ea8c";
}

.crypto-etp::before {
  content: "\ea8d";
}

.crypto-eur::before {
  content: "\ea8e";
}

.crypto-evx::before {
  content: "\ea8f";
}

.crypto-exmo::before {
  content: "\ea90";
}

.crypto-exp::before {
  content: "\ea91";
}

.crypto-fair::before {
  content: "\ea92";
}

.crypto-fct::before {
  content: "\ea93";
}

.crypto-fil::before {
  content: "\ea94";
}

.crypto-fjc::before {
  content: "\ea95";
}

.crypto-fldc::before {
  content: "\ea96";
}

.crypto-flo::before {
  content: "\ea97";
}

.crypto-fsn::before {
  content: "\ea98";
}

.crypto-ftc::before {
  content: "\ea99";
}

.crypto-fuel::before {
  content: "\ea9a";
}

.crypto-fun::before {
  content: "\ea9b";
}

.crypto-game::before {
  content: "\ea9c";
}

.crypto-gas::before {
  content: "\ea9d";
}

.crypto-gbp::before {
  content: "\ea9e";
}

.crypto-gbx::before {
  content: "\ea9f";
}

.crypto-gbyte::before {
  content: "\eaa0";
}

.crypto-generic::before {
  content: "\eaa1";
}

.crypto-gin::before {
  content: "\eaa2";
}

.crypto-glxt::before {
  content: "\eaa3";
}

.crypto-gmr::before {
  content: "\eaa4";
}

.crypto-gno::before {
  content: "\eaa5";
}

.crypto-gnt::before {
  content: "\eaa6";
}

.crypto-gold::before {
  content: "\eaa7";
}

.crypto-grc::before {
  content: "\eaa8";
}

.crypto-grin::before {
  content: "\eaa9";
}

.crypto-grs::before {
  content: "\eaaa";
}

.crypto-gsc::before {
  content: "\eaab";
}

.crypto-gto::before {
  content: "\eaac";
}

.crypto-gup::before {
  content: "\eaad";
}

.crypto-gusd::before {
  content: "\eaae";
}

.crypto-gvt::before {
  content: "\eaaf";
}

.crypto-gxs::before {
  content: "\eab0";
}

.crypto-gzr::before {
  content: "\eab1";
}

.crypto-hight::before {
  content: "\eab2";
}

.crypto-hodl::before {
  content: "\eab3";
}

.crypto-hot::before {
  content: "\eab4";
}

.crypto-hpb::before {
  content: "\eab5";
}

.crypto-hsr::before {
  content: "\eab6";
}

.crypto-ht::before {
  content: "\eab7";
}

.crypto-html::before {
  content: "\eab8";
}

.crypto-huc::before {
  content: "\eab9";
}

.crypto-hush::before {
  content: "\eaba";
}

.crypto-icn::before {
  content: "\eabb";
}

.crypto-icx::before {
  content: "\eabc";
}

.crypto-ignis::before {
  content: "\eabd";
}

.crypto-ilk::before {
  content: "\eabe";
}

.crypto-ink::before {
  content: "\eabf";
}

.crypto-ins::before {
  content: "\eac0";
}

.crypto-ion::before {
  content: "\eac1";
}

.crypto-iop::before {
  content: "\eac2";
}

.crypto-iost::before {
  content: "\eac3";
}

.crypto-iotx::before {
  content: "\eac4";
}

.crypto-iq::before {
  content: "\eac5";
}

.crypto-itc::before {
  content: "\eac6";
}

.crypto-jnt::before {
  content: "\eac7";
}

.crypto-jpy::before {
  content: "\eac8";
}

.crypto-kcs::before {
  content: "\eac9";
}

.crypto-kin::before {
  content: "\eaca";
}

.crypto-kmd::before {
  content: "\eacb";
}

.crypto-knc::before {
  content: "\eacc";
}

.crypto-krb::before {
  content: "\eacd";
}

.crypto-lbc::before {
  content: "\eace";
}

.crypto-lend::before {
  content: "\eacf";
}

.crypto-link::before {
  content: "\ead0";
}

.crypto-lkk::before {
  content: "\ead1";
}

.crypto-loom::before {
  content: "\ead2";
}

.crypto-lpt::before {
  content: "\ead3";
}

.crypto-lrc::before {
  content: "\ead4";
}

.crypto-lsk::before {
  content: "\ead5";
}

.crypto-ltc::before {
  content: "\ead6";
}

.crypto-lun::before {
  content: "\ead7";
}

.crypto-maid::before {
  content: "\ead8";
}

.crypto-mana::before {
  content: "\ead9";
}

.crypto-mcap::before {
  content: "\eada";
}

.crypto-mco::before {
  content: "\eadb";
}

.crypto-mda::before {
  content: "\eadc";
}

.crypto-mds::before {
  content: "\eadd";
}

.crypto-med::before {
  content: "\eade";
}

.crypto-meetone::before {
  content: "\eadf";
}

.crypto-miota::before {
  content: "\eae0";
}

.crypto-mith::before {
  content: "\eae1";
}

.crypto-mkr::before {
  content: "\eae2";
}

.crypto-mln::before {
  content: "\eae3";
}

.crypto-mnx::before {
  content: "\eae4";
}

.crypto-mnz::before {
  content: "\eae5";
}

.crypto-moac::before {
  content: "\eae6";
}

.crypto-mod::before {
  content: "\eae7";
}

.crypto-mona::before {
  content: "\eae8";
}

.crypto-msr::before {
  content: "\eae9";
}

.crypto-mth::before {
  content: "\eaea";
}

.crypto-mtl::before {
  content: "\eaeb";
}

.crypto-music::before {
  content: "\eaec";
}

.crypto-mzc::before {
  content: "\eaed";
}

.crypto-nano::before {
  content: "\eaee";
}

.crypto-nas::before {
  content: "\eaef";
}

.crypto-nav::before {
  content: "\eaf0";
}

.crypto-ncash::before {
  content: "\eaf1";
}

.crypto-ndz::before {
  content: "\eaf2";
}

.crypto-nebl::before {
  content: "\eaf3";
}

.crypto-neo::before {
  content: "\eaf4";
}

.crypto-neos::before {
  content: "\eaf5";
}

.crypto-neu::before {
  content: "\eaf6";
}

.crypto-nexo::before {
  content: "\eaf7";
}

.crypto-ngc::before {
  content: "\eaf8";
}

.crypto-nio::before {
  content: "\eaf9";
}

.crypto-nlc2::before {
  content: "\eafa";
}

.crypto-nlg::before {
  content: "\eafb";
}

.crypto-nmc::before {
  content: "\eafc";
}

.crypto-npxs::before {
  content: "\eafd";
}

.crypto-nuls::before {
  content: "\eafe";
}

.crypto-nxs::before {
  content: "\eaff";
}

.crypto-nxt::before {
  content: "\eb00";
}

.crypto-oax::before {
  content: "\eb01";
}

.crypto-ok::before {
  content: "\eb02";
}

.crypto-omg::before {
  content: "\eb03";
}

.crypto-omni::before {
  content: "\eb04";
}

.crypto-ong::before {
  content: "\eb05";
}

.crypto-ont::before {
  content: "\eb06";
}

.crypto-oot::before {
  content: "\eb07";
}

.crypto-ost::before {
  content: "\eb08";
}

.crypto-ox::before {
  content: "\eb09";
}

.crypto-pac::before {
  content: "\eb0a";
}

.crypto-part::before {
  content: "\eb0b";
}

.crypto-pasc::before {
  content: "\eb0c";
}

.crypto-pasl::before {
  content: "\eb0d";
}

.crypto-pax::before {
  content: "\eb0e";
}

.crypto-pay::before {
  content: "\eb0f";
}

.crypto-payx::before {
  content: "\eb10";
}

.crypto-pink::before {
  content: "\eb11";
}

.crypto-pirl::before {
  content: "\eb12";
}

.crypto-pivx::before {
  content: "\eb13";
}

.crypto-plr::before {
  content: "\eb14";
}

.crypto-poa::before {
  content: "\eb15";
}

.crypto-poe::before {
  content: "\eb16";
}

.crypto-polis::before {
  content: "\eb17";
}

.crypto-poly::before {
  content: "\eb18";
}

.crypto-pot::before {
  content: "\eb19";
}

.crypto-powr::before {
  content: "\eb1a";
}

.crypto-ppc::before {
  content: "\eb1b";
}

.crypto-ppp::before {
  content: "\eb1c";
}

.crypto-ppt::before {
  content: "\eb1d";
}

.crypto-pre::before {
  content: "\eb1e";
}

.crypto-prl::before {
  content: "\eb1f";
}

.crypto-pungo::before {
  content: "\eb20";
}

.crypto-pura::before {
  content: "\eb21";
}

.crypto-qash::before {
  content: "\eb22";
}

.crypto-qiwi::before {
  content: "\eb23";
}

.crypto-qlc::before {
  content: "\eb24";
}

.crypto-qrl::before {
  content: "\eb25";
}

.crypto-qsp::before {
  content: "\eb26";
}

.crypto-qtum::before {
  content: "\eb27";
}

.crypto-r::before {
  content: "\eb28";
}

.crypto-rads::before {
  content: "\eb29";
}

.crypto-rap::before {
  content: "\eb2a";
}

.crypto-rcn::before {
  content: "\eb2b";
}

.crypto-rdd::before {
  content: "\eb2c";
}

.crypto-rdn::before {
  content: "\eb2d";
}

.crypto-rep::before {
  content: "\eb2e";
}

.crypto-req::before {
  content: "\eb2f";
}

.crypto-rhoc::before {
  content: "\eb30";
}

.crypto-ric::before {
  content: "\eb31";
}

.crypto-rise::before {
  content: "\eb32";
}

.crypto-rlc::before {
  content: "\eb33";
}

.crypto-rpx::before {
  content: "\eb34";
}

.crypto-rub::before {
  content: "\eb35";
}

.crypto-rvn::before {
  content: "\eb36";
}

.crypto-ryo::before {
  content: "\eb37";
}

.crypto-safe::before {
  content: "\eb38";
}

.crypto-salt::before {
  content: "\eb39";
}

.crypto-san::before {
  content: "\eb3a";
}

.crypto-sbd::before {
  content: "\eb3b";
}

.crypto-sberbank::before {
  content: "\eb3c";
}

.crypto-sc::before {
  content: "\eb3d";
}

.crypto-shift::before {
  content: "\eb3e";
}

.crypto-sib::before {
  content: "\eb3f";
}

.crypto-sky::before {
  content: "\eb40";
}

.crypto-slr::before {
  content: "\eb41";
}

.crypto-sls::before {
  content: "\eb42";
}

.crypto-smart::before {
  content: "\eb43";
}

.crypto-sngls::before {
  content: "\eb44";
}

.crypto-snm::before {
  content: "\eb45";
}

.crypto-snt::before {
  content: "\eb46";
}

.crypto-soc::before {
  content: "\eb47";
}

.crypto-spank::before {
  content: "\eb48";
}

.crypto-sphtx::before {
  content: "\eb49";
}

.crypto-srn::before {
  content: "\eb4a";
}

.crypto-stak::before {
  content: "\eb4b";
}

.crypto-start::before {
  content: "\eb4c";
}

.crypto-steem::before {
  content: "\eb4d";
}

.crypto-storj::before {
  content: "\eb4e";
}

.crypto-storm::before {
  content: "\eb4f";
}

.crypto-stq::before {
  content: "\eb50";
}

.crypto-strat::before {
  content: "\eb51";
}

.crypto-sub::before {
  content: "\eb52";
}

.crypto-sumo::before {
  content: "\eb53";
}

.crypto-sys::before {
  content: "\eb54";
}

.crypto-taas::before {
  content: "\eb55";
}

.crypto-tau::before {
  content: "\eb56";
}

.crypto-tbx::before {
  content: "\eb57";
}

.crypto-tel::before {
  content: "\eb58";
}

.crypto-ten::before {
  content: "\eb59";
}

.crypto-tern::before {
  content: "\eb5a";
}

.crypto-tgch::before {
  content: "\eb5b";
}

.crypto-theta::before {
  content: "\eb5c";
}

.crypto-tix::before {
  content: "\eb5d";
}

.crypto-tkn::before {
  content: "\eb5e";
}

.crypto-tks::before {
  content: "\eb5f";
}

.crypto-tnb::before {
  content: "\eb60";
}

.crypto-tnc::before {
  content: "\eb61";
}

.crypto-tnt::before {
  content: "\eb62";
}

.crypto-tomo::before {
  content: "\eb63";
}

.crypto-tpay::before {
  content: "\eb64";
}

.crypto-trig::before {
  content: "\eb65";
}

.crypto-trtl::before {
  content: "\eb66";
}

.crypto-trx::before {
  content: "\eb67";
}

.crypto-tusd::before {
  content: "\eb68";
}

.crypto-tzc::before {
  content: "\eb69";
}

.crypto-ubq::before {
  content: "\eb6a";
}

.crypto-unity::before {
  content: "\eb6b";
}

.crypto-usd::before {
  content: "\eb6c";
}

.crypto-usdc::before {
  content: "\eb6d";
}

.crypto-usdt::before {
  content: "\eb6e";
}

.crypto-utk::before {
  content: "\eb6f";
}

.crypto-veri::before {
  content: "\eb70";
}

.crypto-vet::before {
  content: "\eb71";
}

.crypto-via::before {
  content: "\eb72";
}

.crypto-vib::before {
  content: "\eb73";
}

.crypto-vibe::before {
  content: "\eb74";
}

.crypto-vivo::before {
  content: "\eb75";
}

.crypto-vrc::before {
  content: "\eb76";
}

.crypto-vrsc::before {
  content: "\eb77";
}

.crypto-vtc::before {
  content: "\eb78";
}

.crypto-vtho::before {
  content: "\eb79";
}

.crypto-wabi::before {
  content: "\eb7a";
}

.crypto-wan::before {
  content: "\eb7b";
}

.crypto-waves::before {
  content: "\eb7c";
}

.crypto-wax::before {
  content: "\eb7d";
}

.crypto-wgr::before {
  content: "\eb7e";
}

.crypto-wicc::before {
  content: "\eb7f";
}

.crypto-wings::before {
  content: "\eb80";
}

.crypto-wpr::before {
  content: "\eb81";
}

.crypto-wtc::before {
  content: "\eb82";
}

.crypto-x::before {
  content: "\eb83";
}

.crypto-xas::before {
  content: "\eb84";
}

.crypto-xbc::before {
  content: "\eb85";
}

.crypto-xby::before {
  content: "\eb86";
}

.crypto-xcp::before {
  content: "\eb87";
}

.crypto-xdn::before {
  content: "\eb88";
}

.crypto-xem::before {
  content: "\eb89";
}

.crypto-xin::before {
  content: "\eb8a";
}

.crypto-xlm::before {
  content: "\eb8b";
}

.crypto-xmcc::before {
  content: "\eb8c";
}

.crypto-xmg::before {
  content: "\eb8d";
}

.crypto-xmo::before {
  content: "\eb8e";
}

.crypto-xmr::before {
  content: "\eb8f";
}

.crypto-xmy::before {
  content: "\eb90";
}

.crypto-xp::before {
  content: "\eb91";
}

.crypto-xpa::before {
  content: "\eb92";
}

.crypto-xpm::before {
  content: "\eb93";
}

.crypto-xrp::before {
  content: "\eb94";
}

.crypto-xsg::before {
  content: "\eb95";
}

.crypto-xtz::before {
  content: "\eb96";
}

.crypto-xuc::before {
  content: "\eb97";
}

.crypto-xvc::before {
  content: "\eb98";
}

.crypto-xvg::before {
  content: "\eb99";
}

.crypto-xzc::before {
  content: "\eb9a";
}

.crypto-yoyow::before {
  content: "\eb9b";
}

.crypto-zcl::before {
  content: "\eb9c";
}

.crypto-zec::before {
  content: "\eb9d";
}

.crypto-zel::before {
  content: "\eb9e";
}

.crypto-zen::before {
  content: "\eb9f";
}

.crypto-zest::before {
  content: "\eba0";
}

.crypto-zil::before {
  content: "\eba1";
}

.crypto-zilla::before {
  content: "\eba2";
}

.crypto-zrx::before {
  content: "\eba3";
}
